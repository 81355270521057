import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerComprobante() {
    var url = ConfigAPI.baseURL + "purchase" + Session.getToken();
    return instance.get(url);
  },
  mostrarComprobante(id) {
    var url = ConfigAPI.baseURL + "purchase/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarComprobante(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "purchase" + Session.getToken();
    return instance.post(url,params);
  },
  editarComprobante(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "purchase/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarComprobante(id) {
    var url = ConfigAPI.baseURL + "purchase/"+ id + Session.getToken();
    return instance.delete(url);
  },  
  filtrarComprobante(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },
  filtrarComprobanteById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase/filter/queryById" + Session.getToken();
    return instance.post(url,params);
  },  
  filtrarPagosById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "payments-erp/filter/queryById" + Session.getToken();
    return instance.post(url,params);
  },     

  obtenerProveedores() {
    var url = ConfigAPI.baseURL + "suppliers" + Session.getToken();
    return instance.get(url);
  }, 
  obtenerVoucherBySupplier(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/supplier" + Session.getToken();
    return instance.post(url,params);
  }, 
  obtenerIvaCondition() {
    var url = ConfigAPI.baseURL + "sales-iva-conditions" + Session.getToken();
    return instance.get(url);
  },
  obtenerPercepciones() {
    var url = ConfigAPI.baseURL + "sales-perceptions" + Session.getToken();
    return instance.get(url);    
  },
  obtenerPuntosVentas() {
    var url = ConfigAPI.baseURL + "sales-points-sales" + Session.getToken();
    return instance.get(url);
  },  

  filtrarCurrentsAccounts(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-currents-accounts/filter/query" + Session.getToken();
    return instance.post(url,params);
  },   
  filtrarCurrentsAccountsPending(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-currents-accounts/filter/query/pending" + Session.getToken();
    return instance.post(url,params);
  },    
  filtrarCurrentsAccountsInvoice(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-currents-accounts/filter/query/invoice" + Session.getToken();
    return instance.post(url,params);
  },  

  agregarAdjuntoCompra(data, file) {  
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }   
    }
          
    var params = formData
    var url = ConfigAPI.baseURL + "purchase/store/file" + Session.getToken();
    return instance.post(url,params,{headers});
  },

  filtrarPendienteImputar(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "payments-erp/filter/pendingImpute" + Session.getToken();
    return instance.post(url,params);
  },     

  obtenerRemitosSinRelacionar(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-remits/filter/query-detail-remits-not-related" + Session.getToken();
    return instance.post(url,params);
  },
  guardarComprobanteRelacionadosRemitos(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-remits/save/purchase-related" + Session.getToken();
    return instance.post(url,params);
  },
  desvincularDetalleRemito(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-remits/delete/purchase-related" + Session.getToken();
    return instance.post(url,params);    
  },

  obtenerCategoriaCompra() {
    var url = ConfigAPI.baseURL + "purchase-category" + Session.getToken();
    return instance.get(url);
  },
  mostrarCategoriaCompra(id) {
    var url = ConfigAPI.baseURL + "purchase-category/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCategoriaCompra(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "purchase-category" + Session.getToken();
    return instance.post(url,params);
  },
  editarCategoriaCompra(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-category/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCategoriaCompra(id) {
    var url = ConfigAPI.baseURL + "purchase-category/"+ id + Session.getToken();
    return instance.delete(url);
  },  

}

export default servicesAPI;